<template>
  <form @submit.prevent="submitDeliverydate()" class="mb-3">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-12 col-xl-6 order-2 order-md-1 order-lg-2 order-xl-1 mb-4 picker">
        <input
          type="text"
          class="datepicker form-control"
          placeholder="Select delivery date"
          v-model="formData.delivery_date"
          :class="{ 'is-invalid': $errors.has('delivery_date') }"
        />
        <div class="invalid-feedback" v-if="$errors.has('delivery_date')">
          {{ $errors.first("delivery_date") }}
        </div>
      </div>

      <!-- Rush Delivery -->
      <div class="col-12 col-md-6 col-lg-12 col-xl-6 order-1 order-md-2 order-lg-1 order-xl-2 mb-4 rush-delivery">
        <div class="card">
          <div class="card-body">
            <p class="rush-text">Click and receive rush delivery service</p>
            <div class="form-group--block">
              <input class="form-check-input" type="checkbox" id="rushDeliverys" @change="isRush = !isRush" />
              <label class="label-checkbox mb-3" for="rushDeliverys"><b>Rush delivery?</b></label
              ><i class="icon-truck ml-4"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group--block">
      <label>Special note (optional)</label>
      <textarea
        class="form-control"
        rows="2"
        cols="50"
        placeholder="Note about your orders, e.g special notes for delivery."
        v-model="formData.note"
      ></textarea>
    </div>
    

     <div class="form-group--block mg-t-10">
        <div class="float-left">
          <button type="button" class="btn btn-theme" @click="$router.back()">
            <i class="fa fa-long-arrow-left"></i>
              Shopping Cart
          </button>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-theme">
            {{ isLoading ? "Please wait.." : "Payment Detail" }}
            <i class="fa fa-long-arrow-right"></i>
          </button>
        </div>
        
      </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import flatpickr from "flatpickr";
require("flatpickr/dist/themes/light.css");

export default {
  data() {
    return {
      isRush: false,
      minDate: new Date().fp_incr(3),
      isRushDate: new Date().fp_incr(1),
      maxDate: new Date().fp_incr(120),
      isRushMaxDate: new Date().fp_incr(2),
      formData: {
        delivery_date: "",
        note: "",
      },
    };
  },

  props: {
    onChangePage: {
      type: Function,
    },
  },

  watch: {
    isRush() {
      this.flatpickerDate();
    },
  },

  mounted() {
    this.flatpickerDate();
  },

  computed:{
    ...mapGetters('cart',['isLoading'])
  },
  
  methods: {
    ...mapActions("cart", ["saveDeliveryDetails"]),

    submitDeliverydate() {
      this.saveDeliveryDetails(this.formData).then(() => {
        this.$router.push({name: 'checkout-payment'})
      });
    },

    flatpickerDate() {
      var minDate = this.minDate;
      var maxDate = this.maxDate;
      if (this.isRush) {
        minDate = this.isRushDate;
        // maxDate = this.isRushMaxDate;
      }

      flatpickr(".datepicker", {
        dateFormat: "Y-m-d",
        inline: true,
        minDate: minDate,
        maxDate: maxDate,
        monthSelectorType: "static",
        enableTime: false,
      });
    },
  },
};
</script>

<style scoped>
.picker > input {
  font-size: 14px;
  width: 307px;
  height: 40px;
}
.rush-text {
  margin-bottom: 1px;
  color: #5a6171;
}
.icon-truck {
  font-size: 23px;
  color: #5a6171;
}
/* .picker {
  order: 1;
}
.rush-delivery {
  order: 2;
} */
@media screen and (max-width: 414px) {
  /* .picker {
    order: 2;
  }
  .rush-delivery {
    order: 1;
  } */
}
</style>

<style>
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: #ff7200;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: #ff7200;
}
</style>
